:root {
  --color-primary: #1b274e;
  --color-primary-rgb: 27, 39, 78;
  /* --color-primary-lighten: #5578eb; */

  --color-secondary: #89a4ff;

  --color-danger: red;

  --color-black: #000000;

  --color-dark: 51, 51, 51;
  --color-dark-rgb: 51, 51, 51;

  --color-medium: #949494;
  --color-medium-lighten: #bebebe;
  --color-medium-darken: #7e7e7e;

  --color-light: white;
  --color-light-darken: rgb(246, 246, 246);
  /*   --color-light-darken: rgb(237, 237, 237); */

  --color-warning: rgb(233, 146, 93);

  --color-success: rgb(104, 181, 79);
  --color-success-rgb: 104, 181, 79;

  --color-success-light: rgb(123, 231, 87);

  --radius: 5px;

  --radius-bigger: 15px;

  --font-size-huge: 2.5rem;

  --font-size-large: 1.875rem;

  --font-size-medium: 1.25rem;

  --font-size-normal: 1rem;

  --font-size-smaller: 0.875rem;

  --font-size-text-config-label: 1.2rem;

  --padding-basic: 1rem;

  --spacing-mobile: 0.7rem;

  --desktop-menu-width: 350px;
}

html,
body,
#root {
  height: 100%;
  min-height: 100%;
}

@font-face {
  font-family: 'oswald';
  src: url('./assets/fonts/Oswald-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'oswald';
  src: url('./assets/fonts/Oswald-Bold.ttf');
  font-weight: bold;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'oswald';
  -webkit-tap-highlight-color: transparent;
}

html {
  color: var(--color-primary);
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 600px) {
  #vopLabel {
    display: none !important;
  }
  #signInMain {
    margin-left: 0 !important;
  }
  #kontakt{
    margin-right: 8px !important;
  }
  #vopLabelZkrt {
    display: block !important;
  }
  #loginLabel {
    display: none !important;
  }
  #loginImage {
    margin-right: -.4em !important;
  }
  #disableMobileTextReg{
    display: none !important;
  }
  #disableMobileTextRegZkrt {
    display: block !important;
  }
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
